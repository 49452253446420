import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type IPlayer } from "@source/types";
import { type RootState } from "@store/store";

import { logoutUser } from "../user/actions";

const initialState = {
  players: [] as IPlayer[],
  status: "idle",
};

export const playersSlice = createSlice({
  name: "players",
  initialState,

  reducers: {
    addPlayer: (state, action: PayloadAction<IPlayer>) => {
      const newPlayer = action.payload;
      const existingPlayer = state.players.findIndex(
        (p) => p.id === newPlayer.id
      );
      if (existingPlayer !== -1) {
        state.players[existingPlayer] = newPlayer;
      } else {
        // Card with the ID doesn't exist in the existing deck, push the new card
        state.players.push(newPlayer);
      }
    },
    updatePlayer: (state, action: PayloadAction<Partial<IPlayer>>) => {
      const newPlayer = action.payload;
      const existingPlayer = state.players.findIndex(
        (p) => p.id === newPlayer.id
      );

      if (existingPlayer !== -1) {
        state.players[existingPlayer] = {
          ...state.players[existingPlayer],
          points_answer:
            state.players[existingPlayer].points_answer +
            (newPlayer.points_answer ?? 0),
          points_deceiver:
            state.players[existingPlayer].points_deceiver +
            (newPlayer.points_deceiver ?? 0),
          isOnline: newPlayer.isOnline ?? true,
          isHost: newPlayer.isHost ?? state.players[existingPlayer].isHost,
        };
      }
    },
    removePlayer: (state, action: PayloadAction<Partial<IPlayer>>) => {
      // This will remove the host if he leaves the game
      const isHost = action.payload.isHost;
      const existingPlayer = state.players.findIndex(
        (p) => p.isHost === isHost
      );
      if (existingPlayer !== -1) {
        state.players.splice(existingPlayer, 1);
      }
    },
    resetPlayers: (state) => {
      state.players = [];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(logoutUser.fulfilled, (state) => {
      state.players = [];
      state.status = "idle";
    });
  },
});

export const { addPlayer, updatePlayer, removePlayer, resetPlayers } =
  playersSlice.actions;
export const selectPlayers = (state: RootState): IPlayer[] => {
  return state.players.players;
};
export default playersSlice.reducer;
