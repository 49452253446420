import { type QuestionSchema } from "@client/index";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { type RootState } from "@store/store";

import { logoutUser } from "../user/actions";
import {
  addManyQuestions,
  addOneQuestion,
  setQuestions,
  updateManyQuestions,
  updateOneQuestion,
  clearQuestions,
} from "./actions";

import { editCard } from "../cards/actions";
const questionsAdapter = createEntityAdapter<QuestionSchema>({
  sortComparer: (a, b) => (a.qOrder ?? 1) - (b.qOrder ?? 2),
});

const initialState = questionsAdapter.getInitialState({
  status: "idle",
  error: "",
});

const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(addOneQuestion, (state, action) => {
        if (
          action.payload.id != null &&
          state.entities[action.payload.id] != null
        ) {
          questionsAdapter.removeOne(state, action.payload.id);
        } else {
          questionsAdapter.upsertOne(state, action.payload);
        }
        state.status = "succeeded";
      })
      .addCase(updateOneQuestion, (state, action) => {
        questionsAdapter.updateOne(state, action.payload);
      })
      .addCase(addManyQuestions, (state, action) => {
        if (action.payload.remove) {
          questionsAdapter.removeAll(state);
          state.status = "succeeded";
        } else {
          questionsAdapter.upsertMany(state, action.payload.questions);
          state.status = "succeeded";
        }
      })
      .addCase(setQuestions, (state, action) => {
        questionsAdapter.setAll(state, action.payload);
      })
      .addCase(updateManyQuestions, (state, action) => {
        questionsAdapter.updateMany(state, action.payload);
      })
      .addCase(clearQuestions, (state) => {
        console.log("clearing questions");
        console.log("Before clearing questions:", state.entities);

        questionsAdapter.removeAll(state);
        console.log("AFter clearing questions:", state.entities);
      })
      .addCase(logoutUser.fulfilled, (state) => {
        questionsAdapter.removeAll(state);
        state.status = "idle";
      });
  },
});

export default questionsSlice.reducer;
export const {
  selectAll: selectAllQuestions,
  selectById: selectQuestionById,
  selectIds: selectQuestionsIds,
} = questionsAdapter.getSelectors((state: RootState) => state.questions);
