import { ErrorMessage } from "formik";
import React from "react";

interface CustomErrorMessageProps {
  name: string;
}
const CustomErrorMessage: React.FC<CustomErrorMessageProps> = ({ name }) => {
  return (
    <div className="text-3xs sm:min-h-[20px] min-h-[10px] text-red-500">
      <ErrorMessage name={name} />
    </div>
  );
};

export { CustomErrorMessage };
