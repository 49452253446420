import { type FormikProps } from "formik";
import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { twMerge } from "tailwind-merge";

interface FrontProps {
  status: string | null;
  formik: FormikProps<any>;
  changedValues?: string[];
  type?: "new" | "edit";
  label?: string;
  error?: boolean;
}
const Front: React.FC<FrontProps> = ({
  formik,
  status,
  changedValues,
  type = "edit",
  label = "Front",
  error = false,
}) => {
  return (
    <div>
      <h1
        className={
          "mb-4 max-w-min rounded-full dark:bg-mariana-blue-100 bg-electric-violet-200  px-6 py-1 text-[15px] dark:text-white text-tolopea/50 sm:mb-3 sm:dark:bg-mariana-blue sm:px-8 sm:text-lg"
        }
      >
        {label}
      </h1>

      <TextareaAutosize
        minRows={1}
        maxRows={3}
        name={"term"}
        placeholder={"Main text here"}
        id={"term"}
        className={twMerge(
          `max-h-[120px] w-full overflow-hidden rounded-3xl border-2 bg-transparent px-4 sm:px-8 py-2 text-sm sm:text-3xl sm:text-center text-tolopea/80 font-medium dark:text-electric-violet-200 ${
            status === "success"
              ? "border-solid dark:border-white border-slate-400"
              : "border-dashed border-electric-violet"
          }`,
          `${
            type === "edit" &&
            (changedValues?.includes("term") === true
              ? "bg-mariana-blue"
              : "bg-transparent")
          }`,
          `${error ? "border-red-500" : ""}`
        )}
        onChange={formik.handleChange}
        value={formik.values.term}
      />
    </div>
  );
};
export { Front };
