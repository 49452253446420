/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { banner_response } from '../models/banner_response';
import type { banner_response_all } from '../models/banner_response_all';
import type { Body_create_banner } from '../models/Body_create_banner';
import type { Body_update_banner } from '../models/Body_update_banner';
import type { LlmDataResponse } from '../models/LlmDataResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdminService {

    /**
     * Retrieve Active Banner
     * @returns banner_response Successful Response
     * @throws ApiError
     */
    public static retrieveActiveBanner(): CancelablePromise<banner_response> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/info_banner',
        });
    }

    /**
     * Create Banner
     * @param formData 
     * @returns banner_response Successful Response
     * @throws ApiError
     */
    public static createBanner(
formData: Body_create_banner,
): CancelablePromise<banner_response> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/info_banner',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Banner
     * @param bannerId 
     * @param formData 
     * @returns banner_response Successful Response
     * @throws ApiError
     */
    public static updateBanner(
bannerId: number,
formData: Body_update_banner,
): CancelablePromise<banner_response> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/admin/info_banner/{banner_id}',
            path: {
                'banner_id': bannerId,
            },
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Retreive All Banners
     * @returns banner_response_all Successful Response
     * @throws ApiError
     */
    public static retreiveAllBanners(): CancelablePromise<banner_response_all> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/info_banner/all',
        });
    }

    /**
     * Get Llm Data
     * @returns LlmDataResponse Successful Response
     * @throws ApiError
     */
    public static getLlmData(): CancelablePromise<LlmDataResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/data',
        });
    }

    /**
     * Upvote Record
     * @param recordId 
     * @returns LlmDataResponse Successful Response
     * @throws ApiError
     */
    public static upvoteRecord(
recordId: number,
): CancelablePromise<LlmDataResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/data/{record_id}/upvote',
            path: {
                'record_id': recordId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Downvote Record
     * @param recordId 
     * @returns LlmDataResponse Successful Response
     * @throws ApiError
     */
    public static downvoteRecord(
recordId: number,
): CancelablePromise<LlmDataResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/data/{record_id}/downvote',
            path: {
                'record_id': recordId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
